.wrapper{
    width: 420px;
    background: transparent;
    border: 2px solid rgba(255,255,255,0.2);
    backdrop-filter: blur(30px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    border-radius: 10px;
    padding: 30px 40px;

}
.wrapper h1{
    font-size: 36px;
    text-align: center;
}
.wrapper .input-box{
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}
.input-box input{
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 2px solid rgba(255,255,255,0.2);
    border-radius: 40px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.input-box input::placeholder{
    color: #fff;
}
.wrapper input[type="submit"]{ 
    width: 100%;
    height: 45px;
    background: #4fc3e0;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    font-weight: 700;
    text-align: center;
    line-height: 5px; /* Add this line */
    display: inline-block; /* Ensure this is inline-block */
}
.wrapper input[type="submit"]:hover{
    background: #4ab9d4;
    color: #333;
    font-weight: 700;
    text-align: center;
    line-height: 5px; /* Add this line */
    display: inline-block; /* Ensure this is inline-block */

} 